<template>
    <div class="complementaries-products">
        <store-product 
            :data_products="data_products">
        </store-product>
        <!-- Footer -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Productos Complementarios',
		meta: [
			// Ummami Express
			{ name: 'description', content:'Fabricantes de estantería para almacenamiento manual, Productos Complementarios, entrepaños, entrepanos, Productos Complementarios bucaramanga' },
			{ name: 'keywords', content:'estantería metálica, mezzanines, entrepisos modulares, productos complementarios' },
			{ name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Productos Complementarios'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'Fabricantes de Productos Complementarios modulares'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Productos Complementarios'},
			{name: 'twitter:description', content: 'Fabricantes de Productos Complementarios modulares' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Productos Complementarios'},
			{itemprop: 'description', content: 'Fabricantes de Productos Complementarios modulares' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    metaInfo() {
        return {
            title: this.page_title,
        }
    },
    data() {
        return {
            page_title: 'Productos Complementarios',
            data_products: {
                title_page: 'Productos complementarios',
                types: [
                    { text: 'Caja Móvil con Divisores', value: 'caja-movil-con-divisores' },
                    { text: 'Divisiones Móviles para Estantería', value: 'divisiones-moviles-para-estanteria' },
                    { text: 'Muebles Gaveteros Cajas Metálicas', value: 'muebles-gaveteros-cajas-metalicas' },
                    { text: 'Contenedores Apilables y de Malla', value: 'contenedores-apilables-y-de-malla' },
                    { text: 'Barreras y Protectores de Marco', value: 'barreras-y-protectores-de-marco' },
                    { text: 'Plataformas para Picking', value: 'plataformas-para-picking-con-montacargas-convencional' },
                    { text: 'Carros Multiusos & Picking', value: 'carros-multiusos-y-picking' },
                    { text: 'Estibas Metalicas', value: 'estibas-metalicas' },
                    { text: 'Unidades Modulares de Trabajo', value: 'unidades-modulares-de-trabajo' }
                ],
                products: [
                    {
                        name: 'Caja Móvil con Divisores',
                        description: `
                            <p>
                                Diseñado para complementar las piezas
                                pequeñas en su estantería organizando sus productos de manera correcta
                                permitiendo inventarios prácticos. Sus medidas son de 40 cm x 60 cm x 13
                                cm. Los divisores y cremalleras son metálicos con recubrimiento
                                electrostático.
                            </p> 
                            <p>CARACTERÍSTICAS</p>
                            <ul class="pl-3">
                                <li class="mb-2">Espacios variables de acuerdo al tamaño de las piezas.</li>
                                <li class="mb-2">Facilidad de manejo.</li>
                                <li class="mb-2">Ubicación donde se necesite.</li>
                                <li class="mb-2">Apilable.</li>
                                <li class="mb-2">Resistente caja plástica.</li>
                                <li class="mb-2">Multiusos, útil para aplicaciones, uso de repuestos, perfumería, mecánica, electrónica, ensamble, farmacia etc.</li>
                            </ul> 
                        `,
                        url_image: 'estanterias-metalicas/complementarios/productos/caja-movil/caja_1.jpg',
                        slides: [
                            'estanterias-metalicas/complementarios/productos/caja-movil/caja_1.jpg',
                            'estanterias-metalicas/complementarios/productos/caja-movil/caja_2.jpg',
                            'estanterias-metalicas/complementarios/productos/caja-movil/caja_3.jpg'
                        ],
                        price: '',
                        tags: ['caja-movil-con-divisores']
                    },
                    {
                        name: 'Divisiones Móviles para Estantería',
                        description: `
                            <p>
                                Son un tipo de estantería que permite
                                organizar piezas de cualquier forma o tamaño a un espacio muy reducido.
                                Sus elementos son muy intercambiables y pueden ser sustituidos de una
                                estantería a otra muy fácilmente. Las aletas divisoras se pueden acomodar
                                al tamaño de las piezas mediante ranuras hechas en el frontal y espaldar.
                            </p> 
                            <p>CARACTERÍSTICAS</p>
                            <ul class="pl-3">
                                <li class="mb-2">Puede localizar sus piezas visual y rápidamente.</li>
                                <li class="mb-2">Ideales para inventarios muy variados.</li>
                            </ul> 
                        `,
                        url_image: 'estanterias-metalicas/complementarios/productos/divisiones-moviles/division_2.jpg',
                        slides: [
                            'estanterias-metalicas/complementarios/productos/divisiones-moviles/division_2.jpg',
                            'estanterias-metalicas/complementarios/productos/divisiones-moviles/division_3.jpg',
                            'estanterias-metalicas/complementarios/productos/divisiones-moviles/division_4.jpg'
                        ],
                        price: '',
                        tags: ['divisiones-moviles-para-estanteria']
                    },
                    {
                        name: 'Muebles Gaveteros Cajas Metálicas',
                        description: `
                            <p>
                                Es un diseño de estantería ideal
                                para proteger repuestos, elementos delicados y pequeños del medio
                                ambiente como el polvo, la luz y la humedad. Estos muebles gaveteros
                                cuentan cajones deslizantes, sin pistas y con un tope al final para retener la
                                caja en forma inclinada y favorecer la inspección. Permiten ahorran un gran
                                espacio al almacenar un número de ítems en un sitio reducido y controlado.
                            </p> 
                            <p>CARACTERÍSTICAS</p>
                            <ul class="pl-3">
                                <li class="mb-2">Divisores móviles para adaptarse a cualquier pieza.</li>
                                <li class="mb-2">Con porta tarjetas para identificar el contenido de cada pieza.</li>
                                <li class="mb-2">Cero mantenimientos.</li>
                                <li class="mb-2">Resistente con cubrimiento de pintura electrostática Epoxi-poliéster.</li>
                            </ul> 
                        `,
                        url_image: 'estanterias-metalicas/complementarios/productos/gavetero/gavetero_1.jpg',
                        slides: [
                            'estanterias-metalicas/complementarios/productos/gavetero/gavetero_1.jpg',
                            'estanterias-metalicas/complementarios/productos/gavetero/gavetero_2.jpg',
                            'estanterias-metalicas/complementarios/productos/gavetero/gavetero_3.jpg'
                        ],
                        price: '',
                        tags: ['muebles-gaveteros-cajas-metalicas']
                    },
                    {
                        name: 'Contenedores Apilables y de Malla',
                        description: `
                            <p>
                                Se trata de contenedores modulares
                                con piezas intercambiables que se arman sobre estibas estándar de 1.00
                                metros x 1.20 metros. Son totalmente apilables y se emplean para objetos
                                muy pesados, inestables o disparejos como bultos, paquetes, garrafas,
                                retales etc.
                            </p> 
                            <p>
                                Estos contenedores igualan la geometría y el tamaño de las cargas
                                brindando así el almacenamiento práctico en estanterías, o simplemente
                                para aislar un determinado producto.
                            </p> 
                        `,
                        url_image: 'estanterias-metalicas/complementarios/productos/contenedores-apilables/apilable_1.jpg',
                        slides: [
                            'estanterias-metalicas/complementarios/productos/contenedores-apilables/apilable_1.jpg',
                            'estanterias-metalicas/complementarios/productos/contenedores-apilables/apilable_2.jpg',
                            'estanterias-metalicas/complementarios/productos/contenedores-apilables/apilable_3.jpg'
                        ],
                        price: '',
                        tags: ['contenedores-apilables-y-de-malla']
                    },
                    {
                        name: 'Barreras y Protectores de Marco',
                        description: `
                            <p>
                                Son barreras protectoras con un seguro
                                sistema para las columnas de estanterías los cuales impiden daños,
                                recibiendo y protegiendo de golpes en equipos de manejo de materiales y
                                así evitar accidentes. Están hechos de materiales de gruesos calibres y son
                                fijados directamente al suelo con anclajes, protegiendo directamente
                                estantería.
                            </p> 
                            <p>CARACTERÍSTICAS</p>
                            <ul class="pl-3">
                                <li class="mb-2">Sus colores son visibles para facilitar la tarea del operario.</li>
                                <li class="mb-2">Evitan que la estantería se tuerza y se rompa.</li>
                            </ul> 
                        `,
                        url_image: 'estanterias-metalicas/complementarios/productos/barreras-y-protectores/barrera_3.jpg',
                        slides: [
                            'estanterias-metalicas/complementarios/productos/barreras-y-protectores/barrera_1.jpg',
                            'estanterias-metalicas/complementarios/productos/barreras-y-protectores/barrera_2.jpg',
                            'estanterias-metalicas/complementarios/productos/barreras-y-protectores/barrera_3.jpg'
                        ],
                        price: '',
                        tags: ['barreras-y-protectores-de-marco']
                    },
                    {
                        name: 'Montacargas Convencional',
                        description: `
                            <p>
                                Es un sistema de estantería especial para evitar graves accidentes al subirse a una estiba
                                con el montacargas. Cuenta con un sistema de acoplado a las uñas del
                                montacargas con seguro para evitar caídas accidentales. Permite además
                                reducir los pasillos de trabajo hasta 1.50m y se gana espacio para su
                                bodega utilizando el mismo montacargas.
                            </p> 
                            <p>CARACTERÍSTICAS</p>
                            <ul class="pl-3">
                                <li class="mb-2">Ideal para inventario físico y a gran altura.</li>
                                <li class="mb-2">Sustituye de forma muy económica al montacargas.</li>
                                <li class="mb-2">Puede tomar o dejar estibas estándar de 1.00 m x 1.20 m cargadas al piso.</li>
                            </ul> 
                        `,
                        url_image: 'estanterias-metalicas/complementarios/productos/montacargas/montacargas_1.jpg',
                        slides: [
                            'estanterias-metalicas/complementarios/productos/montacargas/montacargas_1.jpg',
                            'estanterias-metalicas/complementarios/productos/montacargas/montacargas_2.jpg',
                            'estanterias-metalicas/complementarios/productos/montacargas/montacargas_3.jpg'
                        ],
                        price: '',
                        tags: ['plataformas-para-picking-con-montacargas-convencional']
                    },
                    {
                        name: 'Carros Multiusos & Picking',
                        description: `
                            <p>
                                Es un tipo de estantería
                                desplazable con divisiones y entrepaños removibles de acuerdo al tamaño
                                de la mercancía que se despacha. En un solo recorrido puede cargar
                                mercancía de varios pedidos sin importar la forma, el tamaño o el peso de
                                los paquetes cajas o bultos.
                            </p> 
                            <p>CARACTERÍSTICAS</p>
                            <ul class="pl-3">
                                <li class="mb-2">Ideales ara surtir góndolas de supermercado.</li>
                                <li class="mb-2">Capacidad de 400 kg.</li>
                                <li class="mb-2">Ideal para edificios como sistema de transporte para carga.</li>
                                <li class="mb-2">Se acomoda a los espacios y ascensores.</li>
                            </ul> 
                        `,
                        url_image: 'estanterias-metalicas/complementarios/productos/carros-multiusos/carro_1.jpg',
                        slides: [
                            'estanterias-metalicas/complementarios/productos/carros-multiusos/carro_1.jpg',
                            'estanterias-metalicas/complementarios/productos/carros-multiusos/carro_2.jpg',
                            'estanterias-metalicas/complementarios/productos/carros-multiusos/carro_3.jpg'
                        ],
                        price: '',
                        tags: ['carros-multiusos-y-picking']
                    },          
                    {
                        name: 'Estibas Metalicas',
                        description: `
                            <p>
                                Especialmente diseñadas para manejo en cámaras de
                                refrigeración donde el frio vuelve muy frágiles las estibas de plástico. Son
                                fabricadas en lámina galvanizada y su medida es estándar de 1.00 x 1.20
                                metros. También se tienen pallets metálicos recubiertos en pintura
                                electrostática de alta resistencia para uso general no refrigerado.
                            </p> 
                            <p>CARACTERÍSTICAS</p>
                            <ul class="pl-3">
                                <li class="mb-2">Acabados con soldadura robotizada.</li>
                                <li class="mb-2">Son resistentes al trato rudo.</li>
                                <li class="mb-2">De cuatro entradas para uso general y paletizado selectivo.</li>
                                <li class="mb-2">Especial para sistemas Drive-in.</li>
                            </ul> 
                        `,
                        url_image: 'estanterias-metalicas/complementarios/productos/estibas-metalicas/estiba_2.jpg',
                        slides: [
                            'estanterias-metalicas/complementarios/productos/estibas-metalicas/estiba_1.jpg',
                            'estanterias-metalicas/complementarios/productos/estibas-metalicas/estiba_2.jpg',
                            'estanterias-metalicas/complementarios/productos/estibas-metalicas/estiba_3.jpg'
                        ],
                        price: '',
                        tags: ['estibas-metalicas']
                    }, 
                    {
                        name: 'Unidades Modulares de Trabajo',
                        description: `
                            <p>
                                Se trata de estructuras ergonómicas que
                                poseen una amplia mesa de trabajo con alturas regulables a la talla del
                                usuario. Son modulares especiales para oficinas y áreas de mantenimiento.
                                Contiene repisas móviles que permiten el almacenamiento de documentos
                                o productos a la vista.
                            </p> 
                            <p>CARACTERÍSTICAS</p>
                            <ul class="pl-3">
                                <li class="mb-2">Ideal para fijar en muros.</li>
                                <li class="mb-2">Graduación regulable.</li>
                                <li class="mb-2">Gran capacidad de carga.</li>
                            </ul> 
                        `,
                        url_image: 'estanterias-metalicas/complementarios/productos/modulares/modular_2.jpg',
                        slides: [
                            'estanterias-metalicas/complementarios/productos/modulares/modular_1.jpg',
                            'estanterias-metalicas/complementarios/productos/modulares/modular_2.jpg'
                        ],
                        price: '',
                        tags: ['unidades-modulares-de-trabajo']
                    },
                ]
            }
        }
    },
    computed: {
        url_tag() {
            return this.$route.params.tag;
        }
    },
    watch: {
        url_tag() {
            this.page_title = this.url_tag.replaceAll('-', ' ').capitalize();
        }
    }
}
</script>